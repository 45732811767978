<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-row
        align-v="center"
        align-h="between"
      >
        <!-- Inicio Contrato -->
        <b-col
          cols="12"
          lg="10"
          md="10"
        >
          <label>Tipo</label>
          <v-select
            v-model="typeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            :clearable="false"
            placeholder="Filtrar por tipo"
          />
        </b-col>

        <!-- Filtrar -->
        <b-col
          cols="12"
          lg="2"
          md="2"
          class="pt-2"
        >
          <b-button
            v-if="!isFiltering"
            variant="outline-primary"
            class="mr-2"
            block
            @click="filterData"
          >
            Filtrar
            <feather-icon icon="FilterIcon" />
          </b-button>

          <b-button
            v-else
            variant="outline-danger"
            class="mr-2"
            block
            @click="clearFilter"
          >
            Limpiar
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por titulo..."
              />
              <b-button
                variant="primary"
                @click="editItem('new')"
              >
                <span class="text-nowrap">Crear Plantilla</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPlantillaListTable"
        class="position-relative"
        :items="fetchPlantillas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchPlantillas"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Tipo -->
        <template #cell(type)="data">
          <b-badge :variant="resolveTypeVariant(data.item.type)">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Campos-->
        <template #cell(campos)="data">
          {{ data.item.campos || "-" }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row class="actions">
            <b-button
              :id="`edit-${data.item.id}`"
              @click="editItem(data.item.id)"
              variant="success"
              class="btn-icon"
            >
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Editar</b>
            </b-tooltip>
            <b-button
              :id="`delete-${data.item.id}`"
              @click="deleteModal = true; selectedItem = data.item"
              variant="danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
              <b-tooltip
                :target="`delete-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Eliminar Plantilla</b>
              </b-tooltip>
            </b-button>
          </b-row>
          <ConfirmationModal
            :id="`confirmation-${selectedItem.id}`"
            :show="deleteModal"
            message="Esta acción no se puede revertir y se perderá la plantilla."
            title="¿Está seguro de que desea eliminar la plantilla?"
            @action="deleteItem(selectedItem.id)"
            @close="deleteModal = false"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPlantillas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import usePlantillasList from './usePlantillasList'
import plantillasStoreModule from './plantillasStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      deleteModal: false,
      selectedItem: {},
      typeOptions: ['CONTENIDO', 'CABECERA', 'PIE'],
    }
  },
  methods: {
    editItem(id) {
      this.$router.push({ name: 'plantilla-edit', params: { id } })
    },
    async deleteItem(id) {
      this.$store.dispatch('app-plantilla/deletePlantilla', id).then(() => {
        this.deleteModal = false
        this.refetchData()
      })
    },
  },
  setup() {
    const PLANTILLAS_APP_STORE_MODULE_NAME = 'app-plantilla'

    // Register module
    if (!store.hasModule(PLANTILLAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PLANTILLAS_APP_STORE_MODULE_NAME,
        plantillasStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLANTILLAS_APP_STORE_MODULE_NAME)) store.unregisterModule(PLANTILLAS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPlantillas,
      tableColumns,
      perPage,
      currentPage,
      totalPlantillas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlantillaListTable,
      refetchData,
      typeFilter,
      isFiltering,
      filterData,
      clearFilter,
      resolveTypeVariant,

      // UI
      parseDate,
    } = usePlantillasList()

    return {
      fetchPlantillas,
      tableColumns,
      perPage,
      currentPage,
      totalPlantillas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlantillaListTable,
      refetchData,
      typeFilter,
      isFiltering,
      filterData,
      clearFilter,
      resolveTypeVariant,

      // UI
      parseDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
  .actions .btn-icon {
    margin: 0 3px;
  }
</style>
