import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'plantilla',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchPlantillas({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async listPlantillas({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.listWithoutPagination(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addPlantilla({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.save(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async printPreview({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.printPreview(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchPlantilla({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deletePlantilla({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.delete(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
